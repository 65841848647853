<template>
  <div>
    <div class="zonglan">
<!--      <div class="content">-->
<!--        <div class="title">无锡江南古运河旅游度假区交通指引</div>-->
<!--        <div class="trafficBox">-->
<!--          <div class="trafficList">-->
<!--            <div class="itemTitle">-->
<!--              <div class="icon"><img src="../../assets/img/GJ.png" alt=""></div>-->
<!--              <div class="text">公交路线</div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="itemText">-->
<!--            乘坐公交29路、52路、70路、73路、81路、88路至永安桥下-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="trafficBox">-->
<!--          <div class="trafficList">-->
<!--            <div class="itemTitle bgTL">-->
<!--              <div class="icon"><img src="../../assets/img/GT.png" alt=""></div>-->
<!--              <div class="text">铁路路线</div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="itemText">-->
<!--            无锡的高铁站：无锡东站-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="trafficBox">-->
<!--          <div class="trafficList">-->
<!--            <div class="itemTitle bgFJ">-->
<!--              <div class="icon"><img src="../../assets/img/FJ.png" alt=""></div>-->
<!--              <div class="text">飞机路线</div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="itemText">-->
<!--            附近的机场：苏南硕放国际机场-->
<!--          </div>-->
<!--        </div>-->


<!--      </div>-->
      <div style="text-align: center;">
<!--        <img src="@/assets/img/toutu.png" width="55%" alt="">-->
      </div>
      <div class="demo-image__preview">
        <el-image
            :src="url"
            :preview-src-list="srcList">
        </el-image>
      </div>
<!--      <img width="100%" height="100%" src="@/assets/img/WT.jpg" alt="">-->
    </div>
  </div>
</template>

<script>
import {getBannerowInfo} from "@/api/table";

export default {
  name: "lantu",
  data(){
    return {
      // url: 'https://s1.ax1x.com/2022/05/11/OUAWB6.png',
      url: '/img/shouhui.jpg',
      srcList: [
        // 'https://s1.ax1x.com/2022/05/11/OUAWB6.png'
        '/img/shouhui.jpg'
      ]
    }
  },
  mounted() {
    this.createList()
  },
  methods:{
    createList(){
      getBannerowInfo({
        bannerType:'2',   // 必填 banner类型：1度假区概况 2度假区总览图 3交通指引 4官网首页轮播图 5交通指引（公交、高铁、飞机）6首页度假区概况
        // sightseeingOnline:'1',   // 是否上线 0否 1是
      }).then(res=>{
        console.log(res)
        if (res.code==200) {
          this.url=res.rows[0].image
          this.srcList[0]=res.rows[0].image
          // this.dataInfo.imgList = res.rows[0].image.split(',')
          // this.totalCount=res.total
        }
      })
    },
  }
}
</script>

<style scoped lang="less">
.zonglan{
  width: 100%;
  height: 100%;
  background: #fff;
  .content{
    .title{
      font-size: 24px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      margin-bottom: 35px;
      text-align: center;
    }
    .trafficBox{
      .trafficList{
        width: 100%;
        height: 70px;
        border-bottom: 4px solid #007B8A;
        box-sizing: border-box;
        .itemTitle{
          width: 240px;
          height: 66px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #007B8A;
          background-image: url("../../assets/img/GJ1.png");
          background-repeat: no-repeat;
          background-position: right;
          background-size: 80px;
          border-radius: 5px 35px 35px 0px;
          //padding: 19px 30px 18px 36px;
          .icon{
            width: 38px;
            img{
              width: 100%;
            }
          }
          .text{
            font-size: 24px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            margin-left: 16px;
          }
        }
        .bgTL{
          background-image: url("../../assets/img/GT1.png");
        }
        .bgFJ{
          background-image: url("../../assets/img/FJ1.png");
        }
      }
      .itemText{
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 28px;
        margin: 40px 0 60px 0;
        padding-left: 36px;
      }
    }

  }
}
</style>
